/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:de8059f2-c55a-4686-bb0b-b458962aa5c4",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ZdWLv3eL4",
    "aws_user_pools_web_client_id": "5mth2tptp11hnm6cp37d8tbpuk",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "retinsightuploadtoolfe261c06545e49d5906580d1c3093659-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_content_delivery_bucket": "upload.retinsight.cloud-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d1x52kbiyts7wi.cloudfront.net"
};


export default awsmobile;
